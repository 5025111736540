<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="titlePage" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="instalmentDialog" width="600">
      <v-card class="mx-auto">
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="instalments"
          hide-default-footer
        >
          <template v-slot:[`item.amount`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.amount"
              @save="save(item)"
              @cancel="cancel"
              @open="open"
              @close="close"
            >
              R$ {{ formatPrice(item.amount) }}
              <template v-slot:input>
                <money
                  v-model="item.amount"
                  label="Edit"
                  single-line
                  v-bind="money"
                ></money>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.dueDate`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.dueDate"
              @save="save(item)"
              @cancel="cancel"
              @open="open"
              @close="close"
            >
              {{ dateFormat(item.dueDate) }}
              <template v-slot:input>
                <v-text-field
                  v-model="item.dueDate"
                  label="Novo Vencimento"
                  single-line
                  type="date"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.number`]="{ item }">
            <span>{{ `${item.frequencyNumber}/${item.frequencyTotal}` }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <v-switch
              small
              v-model="item.paid"
              @change="updatePaid(item.id, $event)"
              :label="item.paid ? 'Sim' : 'Não'"
            ></v-switch>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn dark width="100%" color="#2ca01c" @click="saveAll()"
            >Alterar Todas As Parcelas</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-card>
        <v-card-text>
          <v-container>
            <br />
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="4" class="mt-n3">
                  <v-autocomplete
                    outlined
                    :items="accounts"
                    v-model="accountId"
                    label="Conta"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3" sm="3" class="mt-n3">
                  <v-select
                    outlined
                    :items="[
                      { id: 'C', label: 'Receita' },
                      { id: 'D', label: 'Despesa' },
                    ]"
                    v-model="type"
                    item-value="id"
                    item-text="label"
                    label="Tipo"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="3" class="mt-n9">
                  <span>Valor Total</span>
                  <money
                    class="money"
                    v-model="amount"
                    v-bind="money"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></money>
                </v-col>

                <v-col cols="2" class="mt-n3">
                  <v-text-field
                    type="number"
                    outlined
                    v-model="frequencyTotal"
                    label="Total de Parcelas"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-select
                    outlined
                    :items="[
                      { id: 'W', label: 'Semanal' },
                      { id: 'M', label: 'Mensal' },
                      { id: 'Y', label: 'Anual' },
                    ]"
                    item-text="label"
                    item-value="id"
                    v-model="frequencyType"
                    label="Frquencia"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-select>
                </v-col>

                <v-col cols="3">
                  <v-autocomplete
                    outlined
                    :items="paymentMethods"
                    v-model="paymentMethodId"
                    label="Forma de Pagamento"
                    item-text="title"
                    item-value="id"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    type="date"
                    outlined
                    v-model="referenceDate"
                    label="Data Ocorrência"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    type="date"
                    outlined
                    v-model="dueDate"
                    label="Vencimento Inicial"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="title"
                    label="Descrição"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="type === 'C'" class="mt-n8">
                  <v-autocomplete
                    outlined
                    :items="customers"
                    v-model="customerId"
                    label="Recebido de"
                    item-text="person.corporateName"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" v-if="type === 'D'" class="mt-n8">
                  <v-autocomplete
                    outlined
                    :items="suppliers"
                    v-model="supplierId"
                    label="Pagp Para"
                    item-text="person.corporateName"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6" class="mt-n8">
                  <v-autocomplete
                    outlined
                    :items="categories"
                    v-model="categoryId"
                    label="Classificação"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="blue darken-1" to="/finance/movements">
                  Cancelar
                </v-btn>
                <v-btn
                  dark
                  :loading="loadingAction"
                  width="200px"
                  color="#2ca01c"
                  @click="validate()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
export default Vue.extend({
  title: "FinancialMovement",
  components: { Money, PageHeader, InternalMenu },
  data: () => ({
    titlePage: "Nova Transação",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Contas Financeiras",
            to: "/finance/accounts",
            permission: "finance.accounts.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Classificações Financeiras",
            to: "/finance/categories",
            permission: "finance.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Métodos de Pagamento",
            to: "/finance/payment-methods",
            permission: "finance.payment_methods.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Transações Financeiras",
            to: "/finance/movements",
            permission: "finance.transactions.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Transações Financeiras",
        disabled: false,
        href: "/finance/movements",
      },
      {
        text: "Nova Transação",
        disabled: true,
        href: "/finance/transaction/instalment",
      },
    ],

    valid: true,
    accounts: [],
    movements: [],
    paymentMethods: [],
    categories: [],
    members: [],
    customers: [],
    suppliers: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    title: "",
    referenceDate: null,
    amount: "",
    categoryId: "",
    accountId: null,
    paymentMethodId: "",
    memberId: null,
    title: "",
    type: "C",
    dueDate: new Date(),
    supplierId: null,
    customerId: null,
    paid: false,
    paymentPlan: "I",
    frequencyType: null,
    frequencyTotal: 3,
    openDialog: false,
    fullScreen: false,
    instalments: [],
    instalmentDialog: false,
    initialAmount: 0,
    hins: [
      {
        text: "Nº",
        align: "start",
        value: "numer",
        filtering: false,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Valor",
        align: "start",
        value: "amount",
        filtering: true,
      },
      {
        text: "Pago",
        align: "start",
        value: "paid",
        filtering: true,
      },
    ],
    plans: [
      {
        id: "S",
        label: "Avista",
      },
      {
        id: "R",
        label: "Recorrente",
      },
      {
        id: "I",
        label: "Parcelado",
      },
    ],
    headers: [
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: true,
      },
      {
        text: "Valor R$",
        align: "start",
        value: "amount",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
      { text: "Pago", value: "paid" },
    ],
  }),
  methods: {
    save(item) {
      console.log(item);
      this.snack = true;

      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    async saveAll() {
      for await (const instalment of this.instalments) {
        if (instalment.paid && !instalment.paidDate)
          instalment.paidDate = moment().format("YYYY-MM-DD");
        await http.put(`finance/transactions/${instalment.id}`, instalment);
      }
      this.snackbar.text = "Parcelas Atualizadas com Sucesso";
      this.snackbar.color = "success";
      this.snackbar.opened = true;
      this.$router.push("/finance/movements");
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },

    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      if (!type) return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return type === "C"
        ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : "-" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(referenceDate) {
      return moment(referenceDate).format("DD/MM/YYYY");
    },
    getItems() {
      this.loading = true;
      http.get("finance/transactions").then((data) => {
        this.movements = data.data;
        this.instalments = data.data;
        this.loading = false;
      });
    },
    getPayments() {
      this.loading = true;
      http.get("finance/payment-methods").then((data) => {
        this.paymentMethods = data.data;
        this.loading = false;
      });
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then((data) => {
        this.accounts = data.data;
        this.loading = false;
      });
    },
    getSuppliers() {
      this.loading = true;
      http.get("base/suppliers").then((data) => {
        this.suppliers = data.data;
        this.loading = false;
      });
    },
    getCustomers() {
      this.loading = true;
      http.get("base/customers").then((data) => {
        this.customers = data.data;
        this.loading = false;
      });
    },
    getCategories() {
      this.loading = true;
      http.get("finance/categories").then((data) => {
        this.categories = data.data;
        this.loading = false;
      });
    },

    registerOrUpdate() {
      this.loadingAction = true;
      let payload = {
        referenceDate: this.referenceDate,
        paid: this.paid,
        datetime: new Date(),
        dueDate: this.dueDate,
        paymentPlan: this.paymentPlan,
        type: this.type,
        paymentMethodId: this.paymentMethodId,
        title: this.title,
        accountId: this.accountId,
        categoryId: this.categoryId,
        amount: this.amount,
        customerId: this.customerId,
        supplierId: this.supplierId,
        frequencyType: this.frequencyType,
        frequencyTotal: this.paymentPlan === "S" ? 1 : this.frequencyTotal,
        frequencyNumber: this.paymentPlan === "S" ? 1 : null,
      };

      http.post("finance/transactions", payload).then(
        (res) => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Inserido com Sucesso!";
          this.snackbar.opened = true;
          this.loadingAction = false;
          if (this.paymentPlan === "I") {
            this.instalments = res.data;
            this.instalmentDialog = true;
          }
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
          this.snackbar.opened = true;
          this.loadingAction = false;
        }
      );
    },
    updatePaid(id, e) {
      http
        .patch(`finance/transactions/${id}`, {
          paid: e,
          paidDate: moment().format("YYYY-MM-DD"),
        })
        .then(
          () => {
            this.snackbar.text = "Transação Atualizada";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
          },
          () => {
            this.snackbar.text = "Erro ao Atualizar Transação";
            this.snackbar.color = "red";
            this.snackbar.opened = true;
          }
        );
    },
    closeDialog() {
      this.$refs.form.reset();
      this.id = "";
      this.amount = "";
      this.openDialog = false;
    },
    editItem(item) {
      this.title = item.title;
      this.id = item.id;
      this.amount = item.amount;
      this.categoryId = item.categoryId;
      this.paymentMethodId = item.paymentMethodId;
      this.referenceDate = item.referenceDate;
      this.type = item.type;
      this.accountId = item.accountId;
      this.supplierId = item.supplierId;
      this.customerId = item.customerId;
      this.paymentPlan = item.paymentPlan;
      this.dueDate = item.dueDate;
      this.paid = item.paid;
      this.openDialog = true;
    },
  },

  mounted() {
    const q = this.$route.query?.type;
    if (q && q === "debit") {
      this.type = "D";
      this.titlePage = "Novo Débito Parcelado";
    }
    if (q && q === "credit") {
      this.type = "C";
      this.titlePage = "Novo Crédito Parcelado";
    }
    this.getItems();
    this.getAccounts();
    this.getCategories();
    this.getPayments();
    this.getSuppliers();
    this.getCustomers();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 57px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>